import React from 'react'

type Props = {
  progress: number,
  color?: string,
}

const ProgressBar: React.FC<Props> = (
  { progress, color='bg-indigo-600' }
) => {
  return (
    <div className={`shadow-lg mt-2 w-full max-w-2xl mx-auto rounded-2xl p-1.5 ${color}`}>
      <div className="bg-white rounded-full">
        <div
          className={`rounded-full ${color} text-xs leading-none text-center text-white py-1`}
          style={{ width: `${progress}%` }}
        >
          {progress}%
        </div>
      </div>
    </div>
  )
}

export default ProgressBar
