import React from 'react'

type Props = {
  img: string,
  title: string,
  message: string,
  subtitle?: React.JSX.Element,
  error: Error,
}

const Message: React.FC<Props> = (
  { img, title, message, subtitle, error }
) => {
  const errorColor = error ? 'text-red-500 dark:text-red-500' : 'text-slate-400 dark:text-slate-500'

  return (
    <div className="flex items-center py-2 px-4 sm:py-4 sm:px-8 sm:max-h-xs mx-auto bg-white dark:bg-gray-800 rounded-xl shadow-2xl space-y-0 space-x-6">
      <img className="mx-auto rounded-full size-16 sm:size-24" src={img} alt="MerchTracker Logo" />
      <div className="sm:space-y-2 text-left">
        <p className="text-sm sm:text-lg font-semibold text-black dark:text-white">{title}</p>
        { subtitle &&
          <div className="text-slate-500 text-[0.65rem] sm:text-sm">{subtitle}</div>
        }
        <p id="typewriter" className={`animate-typing text-xs sm:text-base overflow-hidden whitespace-nowrap border-r-4 border-r-slate-500 pr-2 font-medium ${errorColor}`}>{message}</p>
      </div>
    </div>
  )
}

export default Message
