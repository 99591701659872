import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

type Props = SvgIconProps;

export const PatreonIcon: React.FC<Props> = (props) => {
  return (
    <SvgIcon
      {...props}
    >
      <svg
        className={`fill-black dark:fill-white ${props.className}`}
        xmlns="http://www.w3.org/2000/svg"
        aria-label="Patreon" role="img"
        viewBox="0 0 512 512">
          {/* <rect
            width="512" height="512"
            rx="15%"
            fill="#ffffff"
          /> */}
          <circle
            cx="310" cy="223" r="113"
            fill="#ffffff"
          />
          <path
            d="M109 410V110h56v300"
            fill="#ffffff"
          />
      </svg>
    </SvgIcon>
  );
};

export default PatreonIcon;
