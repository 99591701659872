import useSWR from "swr";

let baseURL = ''
if (process.env.NODE_ENV === 'production') {
	baseURL = ''
} else {
	if (process.env.CF_PAGES_URL) {
		baseURL = process.env.CF_PAGES_URL
	} else {
		baseURL = 'http://localhost:8787'
	}
}

const useAPIRequest = (path: string) => {
  if (!path.startsWith('/')) {
    throw new Error('Path must start with /');
  }

  const url = `${baseURL}/api${path}`;
  const { data, error} = useSWR(url);

  if (error) {
    return {
      response: {
        url: url,
        path: path,
        data: null,
        status: error.response?.status || null,
      },
      error: error,
    };
  }

  if (!data) {
    return {
      response: {
        url: url,
        path: path,
        data: null,
        status: null,
      },
    };
  }

  // convert the response to JSON
  return {
    response: {
      url: url,
      path: path,
      data: data.data,
      status: data.status,
    },
  };
}

export default useAPIRequest;