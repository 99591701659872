import React from 'react'

type Props = {
  subtitle?: string
  questions: {
    question: string
    answer: string
  }[]
}

const chevron = <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" className="mt-1.5 md:mt-0 flex-shrink-0 h-5 w-5 text-[#5B5675]" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M16.293 9.293 12 13.586 7.707 9.293l-1.414 1.414L12 16.414l5.707-5.707z"></path></svg>

const FAQ: React.FC<Props> = (
  {
    subtitle,
    questions
  }
) => {
  return (
    <section className="max-w-5xl mx-auto mb-20 py-10 sm:py-20">
      <div className="flex items-center justify-center flex-col gap-y-2 py-5">
        <h2 className="text-center text-2xl md:text-3xl lg:text-4xl font-bold text-black dark:text-white">Frequently asked questions</h2>
        {subtitle &&
          <p className="text-lg font-medium text-slate-700/70 dark:text-slate-400">{subtitle}</p>
        }
      </div>
      <div className="w-full px-7 md:px-10 xl:px-2 py-4">
        <div className="mx-auto w-full max-w-5xl border border-slate-400/20 rounded-lg bg-white dark:bg-gray-800">
          {questions.map((item, idx) => (
            <div key={idx} className="border-b border-[#0A071B]/10">
              <button
                className="flex w-full items-start gap-x-5 justify-between rounded-lg text-left text-lg font-bold text-slate-800 dark:text-white focus:outline-none p-5"
                data-toggle="answer-1"
                onClick={e => {
                  const current = e.currentTarget as HTMLElement;
                  const target = e.currentTarget.nextElementSibling as HTMLElement;

                  const svgElement = current.querySelector('svg');
                  if (!svgElement) {
                    return;
                  }

                  const isExpanded = target.style.display === 'block';
                  if (isExpanded) {
                    target.style.display = 'none';
                    svgElement.classList.remove('rotate-180');
                  } else {
                    target.style.display = 'block';
                    svgElement.classList.add('rotate-180');
                  }
                }}
              >
                <span>{item.question}</span>
                { chevron }
              </button>
              <div className="answer pt-2 pb-5 px-5 text-sm lg:text-base text-[#343E3A] dark:text-slate-400 font-medium" id="answer-1" style={{ display: 'none' }}>
                {item.answer}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default FAQ
