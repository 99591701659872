import React from 'react'
import TikTokIcon from './icons/TikTok'
import GitHubIcon from '@mui/icons-material/GitHub';
import PatreonIcon from './icons/Patreon';

const Socials: React.FC = () => {
  const socials = [
    {
      name: 'GitHub',
      url: 'https://github.com/swiftsudo/merchtracker.justarecord.app',
      icon: <GitHubIcon fontSize='large' className='' />,
    },
    {
      name: 'TikTok',
      url: 'https://tiktok.com/@merchtracker',
      icon: <TikTokIcon fontSize='large' className='' />,
    },
    {
      name: 'Patreon',
      url: 'https://patreon.com/merchtracker',
      icon: <PatreonIcon fontSize='large' className='' />,
    }
  ]

  return (
    <div className="flex flex-wrap justify-center gap-2">
      {socials.map((social, index) => (
        <button key={index} type="button" aria-label={social.name} className="p-2 font-semibold text-white inline-flex items-center space-x-2 rounded transform ease-in-out hover:scale-110">
          <a href={social.url} target="_blank" rel="noreferrer">
            {social.icon}
          </a>
        </button>
      ))}
    </div>
  )
}

export default Socials
