import React from 'react'

import DateDisplay from '../components/DateDisplay'
import Message from '../components/Message'
import useAPIRequest from '../utils/useAPIRequest'
import FancyHeading from '../components/FancyHeading'
import ProgressBar from '../components/ProgressBar'
import Delay from '../components/Delayed'
import Socials from '../components/Socials'
import BgCircles from '../components/BgCircles'
import FAQ from '../components/FAQ'

const HomePage: React.FC = () => {
  const result = useAPIRequest('/');
  let error = result.error;
  if (error) {
    console.error(error);
  }

  const response = result.response;

  const apiDelay = 2000;

  console.log(response.url);

  const status = response.status;
  const data = response.data;

  const faqResult = useAPIRequest('/faq');
  error = faqResult.error;
  if (error) {
    console.error(error);
  }

  const responseFAQ = faqResult.response;
  let questions = [];

  if (responseFAQ.data && responseFAQ.data.questions) {
    questions = responseFAQ.data.questions;
  }

  return (
    <div className="flex h-screen overflow-x-hidden items-center justify-center bg-gradient-to-t to-blue-dark from-blue-darkest p-10">
      <BgCircles />
      <div className="z-0 w-max p-10 max-h-screen">
        <FancyHeading img="/banner.svg" />

        <div className="mt-5 sm:mt-10 flex flex-col text-center justify-center w-full text-black dark:text-white">
          <h1 className="text-3xl sm:text-5xl">We are <b>Almost</b> ready!</h1>
          <p className="text-base sm:text-xl">Stay tuned for something amazing!</p>
          <div className="mt-5 sm:mt-10 mb-5 sm:mb-10">
            <ProgressBar color='bg-gradient-to-r from-pink to-purple' progress={15} />
            <DateDisplay />
          </div>
          <div className="flex text-white mx-auto">
            <Socials />
          </div>
        </div>
        <FAQ questions={questions} />
      </div>
      <Delay delay={apiDelay}>
        <div id='apiBox' className="fixed left-5 bottom-5 z-40 transform transition-all duration-150 ease-in-out">
          <Message
            img='/logo512.png'
            title='MerchTracker API'
            message={!error && data ? data.message : error ? error.message : 'Loading...'}
            subtitle={(
              <div className="font-mono">
                <p>{`>`} GET /api{response.path} HTTP/2</p>
                { status &&
                  <p>{`<`} HTTP/2 {status}</p>
                }
              </div>
            )}
            error={error}
          />
        </div>
      </Delay>
    </div>
  )
}

export default HomePage
