import React from 'react';

const BgCircles: React.FC = () => {
  return (
    <div className="absolute -z-1 w-full h-screen">
      <ul className="circles">
        {Array.from({ length: 10 }).map((_, i) => (
          <li key={i} />
        ))}
      </ul>
    </div>
  );
}

export default BgCircles;