import React, { useState, useEffect } from 'react'

type Props = {
  children: React.ReactNode,
  delay: number,
}

const Delay = ({ children, delay }: Props) => {
  const [isShown, setIsShown] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true)
    }, delay)

    return () => {
      clearTimeout(timer)
    }
  }, [delay])

  return isShown ? children : null
}

export default Delay
